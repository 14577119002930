import React from 'react';
import { Link } from 'react-router-dom';

export default function Nav() {
  const menu = [
    {
      label: 'Terms of service',
      url: 'terms',
    },
    {
      label: 'Privacy Policy',
      url: 'privacy',
    },
    {
      label: 'Contact Us',
      url: 'contact',
    },
  ];

  return (
    <nav className="font-barlow uppercase">
      {menu.map((item, key) => {
        return (
          <Link
            className="inline-flex px-4 py-2 tracking-wider text-white font-bold text-sm"
            to={item.url}
            key={`nav-item-${key}`}
          >
            {item.label}
          </Link>
        );
      })}
      <Link
        className=" bg-teal-500 py-3 px-6 font-bold text-white text-sm tracking-wider rounded"
        to="/contact"
      >
        Get started
      </Link>
    </nav>
  );
}
