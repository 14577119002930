import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// compoenets
import HeroBanner from '@/components/HeroBanner';

// assets
import s1Img1 from '@/assets/img/s1-img1.png';

import s2Ico1 from '@/assets/img/s2-icon1.png';
import s2Ico2 from '@/assets/img/s2-icon2.png';
import s2Ico3 from '@/assets/img/s2-icon3.png';

import s4Ico1 from '@/assets/img/s4-icon1.png';
import s4Ico2 from '@/assets/img/s4-icon2.png';
import s4Ico3 from '@/assets/img/s4-icon3.png';
import s4Ico4 from '@/assets/img/s4-icon4.png';
import s4Ico5 from '@/assets/img/s4-icon5.png';
import s4Ico6 from '@/assets/img/s4-icon6.png';

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>Tempo Flex</title>
      </Helmet>

      <HeroBanner />

      {/* section 1: welcome */}
      <section className="py-24">
        <div className="w-full px-4 lg:w-11/12 xl:px-0 xl:w-9/12 2xl:w-[1280px] mx-auto h-full">
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col justify-center col-span-2 lg:col-span-1">
              <h2 className="text-slate-700 text-3xl font-black mb-4">
                Welcome To Meta Time Time, Date & Timezone API
              </h2>
              <p>
                Get the time & date of a location or IP address, while being
                easily able to convert the time & date of one timezone into
                another.
              </p>
              <p>
                The API has been engineered to get up to millisecond time while
                being able to convert time from one location to the other with
                ease. It also includes daylight savings and GMT offset data.
                Compatible with all websites and databases, the API is super
                easy to implement, absolutely accurate & fast!
              </p>
              <div className="mt-4">
                <Link
                  className="bg-teal-500 py-4 px-8 font-bold text-white tracking-wider rounded uppercase inline-flex"
                  to="about"
                >
                  Get Started Today
                </Link>
              </div>
            </div>
            <div className="col-span-2 lg:col-span-1">
              <img src={s1Img1} alt="firendly support" />
            </div>
          </div>
        </div>
      </section>

      {/* section 2: facts */}
      <section className="py-24 bg-slate-100">
        <div className="w-full px-4 lg:w-11/12 xl:px-0 xl:w-9/12 2xl:w-[1200px] mx-auto h-full">
          <div className="text-center mb-12">
            <h2 className="text-slate-700 text-3xl font-black mb-4">
              Why Developers Prefer Our API
            </h2>
            <p>
              Built by developers & for developers the API takes into
              consideration the three important aspects - ease of
              implementation, fast speed & scalability - which are in-built into
              our API, making it easy for developments to deploy & manage.
            </p>
          </div>
          <div className="grid grid-cols-6 gap-4">
            <div className="flex xs:flex-col col-span-6 text-center md:text-left md:col-span-3 lg:col-span-2">
              <div className="flex-auto w-48">
                <img src={s2Ico1} alt="fast" width="64" />
              </div>
              <div className="flex-auto">
                <h3 className="text-slate-700 font-bold text-xl">
                  Fast Implementation
                </h3>
                <p>
                  Clear documentation & demo code ensures you can implement the
                  API on your website or app easily.
                </p>
              </div>
            </div>
            <div className="flex xs:flex-col col-span-6 text-center md:text-left md:col-span-3 lg:col-span-2">
              <div className="flex-auto w-48">
                <img src={s2Ico2} alt="fast" width="64" />
              </div>
              <div className="flex-auto">
                <h3 className="text-slate-700 font-bold text-xl">
                  Fast Implementation
                </h3>
                <p>
                  Clear documentation & demo code ensures you can implement the
                  API on your website or app easily.
                </p>
              </div>
            </div>
            <div className="flex xs:flex-col col-span-6 text-center md:text-left md:col-span-3 lg:col-span-2">
              <div className="flex-auto w-48">
                <img src={s2Ico3} alt="fast" width="64" />
              </div>
              <div className="flex-auto">
                <h3 className="text-slate-700 font-bold text-xl">
                  Fast Implementation
                </h3>
                <p>
                  Clear documentation & demo code ensures you can implement the
                  API on your website or app easily.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-12 text-center">
            <Link
              className="bg-teal-500 py-4 px-8 font-bold text-white tracking-wider rounded uppercase"
              to="about"
            >
              Get Started Today
            </Link>
          </div>
        </div>
      </section>

      {/* section 3: packages */}
      <section className="py-24">
        <div className="w-full px-4 lg:w-11/12 xl:px-0 xl:w-9/12 2xl:w-[1280px] mx-auto h-full">
          <div className="text-center mb-12">
            <h2 className="text-slate-700 text-3xl font-black mb-4">
              Packages To Suit Your Needs
            </h2>
            <p>
              We understand every service has a seperate need and we offer 4
              flexible pricing packages to choose from.
            </p>
          </div>
          <div className="grid grid-cols-8 gap-4">
            <div className="col-span-8 md:col-span-4 xl:col-span-2">
              <div className="bg-indigo-700 py-9 px-4 text-white rounded h-full flex flex-col">
                <h3 className="font-bold text-2xl text-white">Trial Package</h3>
                <div className="text-2xl">$1.00</div>
                <div className="mt-6 mb-9">
                  <ul>
                    <li className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5 mr-1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                          clipRule="evenodd"
                        />
                      </svg>
                      500 requests / month
                    </li>
                    <li className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5 mr-1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                          clipRule="evenodd"
                        />
                      </svg>
                      1 request / second
                    </li>
                  </ul>
                </div>
                <Link
                  className="bg-teal-500 py-4 font-bold text-white tracking-wider rounded uppercase mt-auto text-center"
                  to="checkout/1"
                >
                  Get Started Today
                </Link>
              </div>
            </div>
            <div className="col-span-8 md:col-span-4 xl:col-span-2">
              <div className="bg-indigo-700 py-9 px-4 text-white rounded h-full flex flex-col">
                <h3 className="font-bold text-2xl text-white">
                  Starter Package
                </h3>
                <div className="text-2xl">$129.88</div>
                <div className="mt-6 mb-9">
                  <ul>
                    <li className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5 mr-1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                          clipRule="evenodd"
                        />
                      </svg>
                      55,000 requests / month
                    </li>
                    <li className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5 mr-1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                          clipRule="evenodd"
                        />
                      </svg>
                      50 requests / second
                    </li>
                    <li className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5 mr-1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Commercial use allowed
                    </li>
                    <li className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5 mr-1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Email support
                    </li>
                  </ul>
                </div>
                <Link
                  className="bg-teal-500 py-4 font-bold text-white tracking-wider rounded uppercase mt-auto text-center"
                  to="checkout/2"
                >
                  Get Started Today
                </Link>
              </div>
            </div>
            <div className="col-span-8 md:col-span-4 xl:col-span-2">
              <div className="bg-indigo-700 py-9 px-4 text-white rounded h-full flex flex-col">
                <h3 className="font-bold text-2xl text-white">Pro Package</h3>
                <div className="text-2xl">$169.88</div>
                <div className="mt-6 mb-9">
                  <ul>
                    <li className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5 mr-1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                          clipRule="evenodd"
                        />
                      </svg>
                      350,000 requests / month
                    </li>
                    <li className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5 mr-1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                          clipRule="evenodd"
                        />
                      </svg>
                      500 requests / second
                    </li>
                    <li className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5 mr-1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Commercial use allowed
                    </li>
                    <li className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5 mr-1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Premium enriched data
                    </li>
                    <li className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5 mr-1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Email support
                    </li>
                  </ul>
                </div>
                <Link
                  className="bg-teal-500 py-4 font-bold text-white tracking-wider rounded uppercase mt-auto text-center"
                  to="checkout/3"
                >
                  Get Started Today
                </Link>
              </div>
            </div>
            <div className="col-span-8 md:col-span-4 xl:col-span-2">
              <div className="bg-indigo-700 py-9 px-4 text-white rounded h-full flex flex-col">
                <h3 className="font-bold text-2xl text-white">
                  Enterprise Package
                </h3>
                <div className="text-2xl">$199.88</div>
                <div className="mt-6 mb-9">
                  <ul>
                    <li className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5 mr-1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                          clipRule="evenodd"
                        />
                      </svg>
                      1,250,000 requests / month
                    </li>
                    <li className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5 mr-1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                          clipRule="evenodd"
                        />
                      </svg>
                      500 requests / second
                    </li>
                    <li className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5 mr-1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Commercial use allowed
                    </li>
                    <li className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5 mr-1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Premium enriched data
                    </li>
                    <li className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5 mr-1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Chat support
                    </li>
                  </ul>
                </div>
                <Link
                  className="bg-teal-500 py-4 font-bold text-white tracking-wider rounded uppercase mt-auto text-center"
                  to="checkout/4"
                >
                  Get Started Today
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section 4: CTA */}
      <section className="py-24">
        <div className="w-full px-4 lg:w-11/12 xl:px-0 xl:w-9/12 2xl:w-[1280px] mx-auto h-full">
          <div className="grid grid-cols-9 gap-4">
            <div className="col-span-9 lg:col-span-4">
              <div className="">
                <h2 className="text-slate-700 text-3xl font-black mb-4">
                  Get Ready To Power Your Services With Meta Time API
                </h2>
                <p>
                  Some other reasons that make Meta Time API a top rated
                  solution for services & apps.
                </p>
                <Link
                  className="bg-teal-500 py-4 px-8 font-bold text-white tracking-wider rounded uppercase inline-flex"
                  to="about"
                >
                  Discover Them Here
                </Link>
              </div>
            </div>
            <div className="col-span-9 lg:col-span-5">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-3 md:col-span-2">
                  <div className="bg-slate-100 shadow-lg px-4 py-8 text-center h-full">
                    <img
                      className="mx-auto"
                      src={s4Ico1}
                      alt="fast"
                      width="84"
                    />
                    <h4 className="font-bold text-slate-700">
                      Super Fast Processing
                    </h4>
                  </div>
                </div>
                <div className="col-span-3 md:col-span-2">
                  <div className="bg-slate-100 shadow-lg px-4 py-8 text-center h-full">
                    <img
                      className="mx-auto"
                      src={s4Ico2}
                      alt="fast"
                      width="84"
                    />
                    <h4 className="font-bold text-slate-700">
                      Historical Data Tracking
                    </h4>
                  </div>
                </div>
                <div className="col-span-3 md:col-span-2">
                  <div className="bg-slate-100 shadow-lg px-4 py-8 text-center h-full">
                    <img
                      className="mx-auto"
                      src={s4Ico3}
                      alt="fast"
                      width="84"
                    />
                    <h4 className="font-bold text-slate-700">
                      Secure API Service
                    </h4>
                  </div>
                </div>
                <div className="col-span-3 md:col-span-2">
                  <div className="bg-slate-100 shadow-lg px-4 py-8 text-center h-full">
                    <img
                      className="mx-auto"
                      src={s4Ico4}
                      alt="fast"
                      width="84"
                    />
                    <h4 className="font-bold text-slate-700">
                      Super High Uptime
                    </h4>
                  </div>
                </div>
                <div className="col-span-3 md:col-span-2">
                  <div className="bg-slate-100 shadow-lg px-4 py-8 text-center h-full">
                    <img
                      className="mx-auto"
                      src={s4Ico5}
                      alt="fast"
                      width="84"
                    />
                    <h4 className="font-bold text-slate-700">
                      Flexible Pricing
                    </h4>
                  </div>
                </div>
                <div className="col-span-3 md:col-span-2">
                  <div className="bg-slate-100 shadow-lg px-4 py-8 text-center h-full">
                    <img
                      className="mx-auto"
                      src={s4Ico6}
                      alt="fast"
                      width="84"
                    />
                    <h4 className="font-bold text-slate-700">
                      Instant Chat Support
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePage;
