import React from 'react';
import { Link } from 'react-router-dom';

export default function HeroBanner() {
  return (
    <section className="bg-no-repeat bg-center bg-hero-bg bg-slate-700 h-[580px]">
      <div className="w-full px-4 lg:w-11/12 xl:px-0 xl:w-9/12 2xl:w-[1280px] mx-auto h-full">
        <div className="flex flex-col items-center justify-center h-full text-center">
          <h1 className="text-white text-4xl font-bold mb-4 tracking-wider">
            The Easy To Use API To
            <br />
            Track & Display
            <br />
            Time, Date & Timezone
          </h1>
          <div className="text-white text-xl mb-6">
            A simple API based web service that returns the current local <br />{' '}
            (or IP specific) current local time & date using simple HTML & JSON.
          </div>
          <Link
            className="bg-teal-500 py-4 px-8 font-bold text-white tracking-wider rounded uppercase"
            to="about"
          >
            Get Started Today
          </Link>
        </div>
      </div>
    </section>
  );
}
