import React from 'react';
import { Link } from 'react-router-dom';

// componets
import Nav from './components/Nav';

// assets
import tempoFlex from '@/assets/img/tempoflex.svg';

export default function AppHeader() {
  return (
    <header className="bg-slate-700 lg:py-4">
      <div className="w-full px-4 lg:w-11/12 xl:px-0 xl:w-9/12 2xl:w-[1280px] mx-auto flex items-center">
        <Link className="text-4xl text-white font-barlow" to="/">
          <img src={tempoFlex} width="200" height="32" alt="tempo flex logo" />
        </Link>
        <div className="ml-auto hidden lg:block">
          <Nav />
        </div>
        <div className="ml-auto block lg:hidden">
          <button className="text-white p-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-9 h-9"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 9h16.5m-16.5 6.75h16.5"
              />
            </svg>
          </button>
        </div>
      </div>
    </header>
  );
}
