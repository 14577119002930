import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import validateEmail from '@/utils/helpers';

const ContactPage = () => {
  const formModel = {
    first_name: '',
    last_name: '',
    email: '',
    message: '',
  };
  const [formData, setFormData] = useState(formModel);
  const [formErrors, setFormErrors] = useState({});
  const [formSuccess, setFormSuccess] = useState();

  const contactFormHandle = async (e) => {
    e.preventDefault();

    const errors = {};

    setFormErrors([]);

    for (const data in formData) {
      if (!formData[data]) {
        errors[data] = 'This is field is require';
      }
    }

    if (formData.email) {
      const emailAddress = validateEmail(formData.email);
      if (!emailAddress) {
        errors.email = 'Please enter a valid email address';
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      try {
        const { data } = await axios.post(
          'https://www.tempoflex-api.com/api/1/contact-us-submit',
          formData
        );

        if (data.status === 200) {
          setFormData(formModel);
          setFormSuccess('Your message was succesfully sent!');
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  function inputOnChange(e) {
    const label = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [label]: value });
  }

  useEffect(() => {
    console.log(formErrors);
  }, [formErrors]);

  return (
    <>
      <Helmet>
        <title>Contact Us | Tempo Flex</title>
      </Helmet>

      <main className="py-24">
        <div className="w-full lg:w-[960px] px-4 xl:px-0 mx-auto">
          <h1 className="text-4xl font-bold text-slate-700 mb-6 text-center">
            Get In Touch With Us
          </h1>

          <div className="grid grid-cols-8">
            <div className="bg-indigo-500 col-span-8 md:col-span-3 p-6 text-white">
              <h2 className="font-bold text-2xl mb-6 text-white">
                How Can
                <br /> Tempo Flex Help?
              </h2>
              <div className="mb-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>

                <div className="uppercase ">Write To Us</div>
                <div className="font-bold">care@propermeta-api.com</div>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                  />
                </svg>

                <div className="uppercase text-sm">Call Us</div>
                <div className="font-bold">1-(877) 296-2574</div>
              </div>
            </div>
            <div className="bg-slate-100 col-span-8 md:col-span-5 p-6">
              {formSuccess && (
                <div className="bg-green-100 p-4 rounded text-green-800 border border-green-300 mb-4">
                  {formSuccess}
                </div>
              )}
              <form onSubmit={contactFormHandle}>
                <div className="mb-4">
                  <label className="" htmlFor="first_name">
                    First Name
                  </label>
                  <input
                    id="first_name"
                    className=""
                    name="first_name"
                    type="text"
                    value={formData.first_name}
                    onChange={(e) => inputOnChange(e)}
                  />
                  {formErrors.first_name && (
                    <span className="text-red-600 text-sm">
                      {formErrors.first_name}
                    </span>
                  )}
                </div>
                <div className="mb-4">
                  <label className="" htmlFor="last_name">
                    Last Name
                  </label>
                  <input
                    id="last_name"
                    className=""
                    name="last_name"
                    value={formData.last_name}
                    onChange={(e) => inputOnChange(e)}
                    type="text"
                  />
                  {formErrors.last_name && (
                    <span className="text-red-600 text-sm">
                      {formErrors.last_name}
                    </span>
                  )}
                </div>
                <div className="mb-4">
                  <label className="" htmlFor="email">
                    Email Address
                  </label>
                  <input
                    id="email"
                    className=""
                    name="email"
                    value={formData.email}
                    onChange={(e) => inputOnChange(e)}
                    type="text"
                  />
                  {formErrors.email && (
                    <span className="text-red-600 text-sm">
                      {formErrors.email}
                    </span>
                  )}
                </div>
                <div className="mb-4">
                  <label className="" htmlFor="message">
                    Message
                  </label>
                  <textarea
                    id="message"
                    className=""
                    name="message"
                    value={formData.message}
                    onChange={(e) => inputOnChange(e)}
                  />
                  {formErrors.message && (
                    <span className="text-red-600 text-sm">
                      {formErrors.message}
                    </span>
                  )}
                </div>
                <div className="text-right">
                  <button
                    className="bg-teal-500 py-4 px-8 font-bold text-white tracking-wider rounded uppercase inline-flex"
                    type="submit"
                    value="Submit"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                      />
                    </svg>
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ContactPage;
