import React from 'react';
import { Link } from 'react-router-dom';

// assets
import tempoFlex from '@/assets/img/tempoflex-dark.svg';

export default function AppFooter() {
  return (
    <footer className="py-12">
      <div className="w-full px-4 lg:w-11/12 xl:px-0 xl:w-9/12 2xl:w-[1280px] mx-auto ">
        <img
          className="mx-auto mb-4"
          src={tempoFlex}
          width="200"
          alt="tempo flex logo"
        />
        <nav className="text-center mb-4">
          <Link
            className="inline-flex px-4 py-2 tracking-wide text-slate-800 uppercase font-bold text-sm"
            to="terms"
          >
            Terms of service
          </Link>
          <Link
            className="inline-flex px-4 py-2 tracking-wide text-slate-800 uppercase font-bold text-sm"
            to="privacy"
          >
            Privacy policy
          </Link>
          <Link
            className="inline-flex px-4 py-2 tracking-wide text-slate-800 uppercase font-bold text-sm"
            to="contact"
          >
            Contact Us
          </Link>
        </nav>
        <div className="text-center">
          <p>
            Cicack Holdings INC, 615 Marshall St, Houston, TX 77006
            <br />
            care@propermeta-api.com, 1-(877) 296-2574
          </p>
          <p className="text-slate-500">
            Copyright © 2023 Tempo Flex. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
