import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import uuid from 'react-uuid';
import { postcodeValidator } from 'postcode-validator';
import validateEmail from '@/utils/helpers';

import products from '@/assets/json/products';
import months from '@/assets/json/months';
import years from '@/assets/json/years';
import tempoFlex from '@/assets/img/tempoflex-dark.svg';

const CheckoutPage = () => {
  const { id } = useParams();
  const [product, setProduct] = useState([]);

  const formDataModel = {
    first_name: '',
    last_name: '',
    address: '',
    city: '',
    zip: '',
    phone: '',
    email: '',
    product_id: '',
    checkout_id: uuid(),
    card_type: '',
    card_number: '',
    card_code: '',
    card_expire_month: '',
    card_expire_year: '',
  };

  const [formErrors, setFormErrors] = useState([]);
  const [formData, setFormData] = useState(formDataModel);
  const [formSuccess, setFormSuccess] = useState();

  function selectProduct(id) {
    for (const product of products) {
      if (String(product.id) === id) {
        const selection = product;
        setProduct(selection);
      }
    }
  }

  const checkoutFormSubmit = async (e) => {
    e.preventDefault();

    const errors = {};

    setFormErrors([]);

    // Check required fields
    for (const data in formData) {
      if (!formData[data]) {
        errors[data] = 'This is field is require';
      }
    }

    // Validate email
    if (formData.email) {
      const emailAddress = validateEmail(formData.email);
      if (!emailAddress) {
        errors.email = 'Please enter a valid email address';
      }
    }

    // Validate US zip code
    if (formData.zip) {
      const isValidZip = postcodeValidator(formData.zip, 'US');
      if (!isValidZip) {
        errors.zip = 'Please enter a valid zip code';
      }
    }

    // Validate card string (only numbers and 16 char)
    if (formData.card_number) {
      const containsOnlyNumbers = /^\d+$/.test(formData.card_number);
      const stringLength = formData.card_number.length;
      if (!containsOnlyNumbers || stringLength !== 16) {
        errors.card_number = 'Please enter a valid creadit card number';
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      try {
        // Turn card_month and card_year to integers
        formData.card_expire_month = parseInt(formData.card_expire_month);
        formData.card_expire_year = parseInt(formData.card_expire_year);

        const { data } = await axios.post(
          'https://www.tempoflex-api.com/api/1/checkout',
          formData
        );

        if (data.status === 200) {
          setFormData(formDataModel);
          setFormSuccess('Your message was succesfully sent!');
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  function inputOnChange(e) {
    const label = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [label]: value });
  }

  useEffect(() => {
    selectProduct(id);
  }, [id]);

  useEffect(() => {
    if (product) {
      formData.product_id = product.product_id;
    }
  }, [product]);

  // useEffect(() => {
  //   console.log(formErrors);
  // }, [formErrors]);

  return (
    <>
      {/* DELETE */}
      {console.log(formData)}
      <Helmet>
        <title>Checkout | Tempo Flex</title>
      </Helmet>

      <main>
        <div className="w-full px-4 lg:w-11/12 xl:px-0 xl:w-9/12 2xl:w-[1280px] mx-auto h-full">
          <div className="grid grid-cols-2">
            {/* ============ LEFT COLUMN ============*/}
            <div className="px-8 col-span-2 lg:col-span-1">
              {/* Logo */}
              <div className="py-6">
                <img
                  className="mx-auto"
                  src={tempoFlex}
                  width="200"
                  alt="logo"
                />
              </div>
              {/* form */}
              <form onSubmit={checkoutFormSubmit}>
                {formSuccess && (
                  <div className="bg-green-100 p-4 rounded text-green-800 border border-green-300 mb-4">
                    {formSuccess}
                  </div>
                )}
                {/* ======= Header ======= */}
                <div>
                  <h3 className="text-2xl font-bold text-slate-800 mb-1">
                    Personal Information
                  </h3>
                  <p>For Order Confirmation & Customer Support</p>
                </div>
                {/* ====================== */}
                <div className="grid grid-cols-2 gap-6">
                  <div className="mb-2">
                    <label className="" htmlFor="first_name">
                      First Name
                    </label>
                    <input
                      id="first_name"
                      className=""
                      name="first_name"
                      type="text"
                      value={formData.first_name}
                      onChange={(e) => inputOnChange(e)}
                    />
                    {formErrors.first_name && (
                      <span className="text-red-600 text-sm">
                        {formErrors.first_name}
                      </span>
                    )}
                  </div>
                  <div className="mb-2">
                    <label className="" htmlFor="last_name">
                      Last Name
                    </label>
                    <input
                      id="last_name"
                      className=""
                      name="last_name"
                      type="text"
                      value={formData.last_name}
                      onChange={(e) => inputOnChange(e)}
                    />
                    {formErrors.last_name && (
                      <span className="text-red-600 text-sm">
                        {formErrors.last_name}
                      </span>
                    )}
                  </div>
                </div>
                <div className="mb-2">
                  <label className="" htmlFor="address">
                    Address
                  </label>
                  <input
                    id="address"
                    className=""
                    name="address"
                    type="text"
                    value={formData.address}
                    onChange={(e) => inputOnChange(e)}
                  />
                  {formErrors.address && (
                    <span className="text-red-600 text-sm">
                      {formErrors.address}
                    </span>
                  )}
                </div>
                <div className="mb-2">
                  <label className="" htmlFor="city">
                    City
                  </label>
                  <input
                    id="city"
                    className=""
                    name="city"
                    type="text"
                    value={formData.city}
                    onChange={(e) => inputOnChange(e)}
                  />
                  {formErrors.city && (
                    <span className="text-red-600 text-sm">
                      {formErrors.city}
                    </span>
                  )}
                </div>
                <div className="mb-2">
                  <label className="" htmlFor="zip">
                    Zip Code
                  </label>
                  <input
                    id="zip"
                    className=""
                    name="zip"
                    type="text"
                    value={formData.zip}
                    onChange={(e) => inputOnChange(e)}
                  />
                  {formErrors.zip && (
                    <span className="text-red-600 text-sm">
                      {formErrors.zip}
                    </span>
                  )}
                </div>
                <div className="mb-2">
                  <label className="" htmlFor="phone">
                    Phone
                  </label>
                  <input
                    id="phone"
                    className=""
                    name="phone"
                    type="text"
                    value={formData.phone}
                    onChange={(e) => inputOnChange(e)}
                  />
                  {formErrors.phone && (
                    <span className="text-red-600 text-sm">
                      {formErrors.phone}
                    </span>
                  )}
                </div>
                <div className="mb-2">
                  <label className="" htmlFor="email">
                    Email
                  </label>
                  <input
                    id="email"
                    className=""
                    name="email"
                    type="text"
                    value={formData.email}
                    onChange={(e) => inputOnChange(e)}
                  />
                  {formErrors.email && (
                    <span className="text-red-600 text-sm">
                      {formErrors.email}
                    </span>
                  )}
                </div>
                {/* ======= Header ======= */}
                <div>
                  <h3 className="text-2xl font-bold text-slate-800 mb-1">
                    Payment Information
                  </h3>
                  <p>
                    We Never Store Your Credit Card Number And Your Payment Is
                    Secure.
                  </p>
                </div>
                {/* ====================== */}
                <div className="mb-2">
                  <label className="" htmlFor="card_type">
                    Card
                  </label>
                  <select
                    id="card_type"
                    className=""
                    name="card_type"
                    value={formData.card_type}
                    onChange={(e) => inputOnChange(e)}
                  >
                    <option>Select Card</option>
                    <option value="visa">Visa</option>
                    <option value="mastercard">Mastercard</option>
                    <option value="discover">Discover</option>
                  </select>
                  {formErrors.first_name && (
                    <span className="text-red-600 text-sm">
                      {formErrors.first_name}
                    </span>
                  )}
                </div>
                <div className="mb-2">
                  <label className="" htmlFor="card_number">
                    Card Number
                  </label>
                  <input
                    id="card_number"
                    className=""
                    name="card_number"
                    type="text"
                    maxLength="16"
                    value={formData.card_number}
                    onChange={(e) => inputOnChange(e)}
                  />
                  {formErrors.card_number && (
                    <span className="text-red-600 text-sm">
                      {formErrors.card_number}
                    </span>
                  )}
                </div>
                <div className="grid grid-cols-3 gap-6 mb-4">
                  <div className="mb-2">
                    <label className="" htmlFor="card_expire_month">
                      Month
                    </label>
                    <select
                      id="card_expire_month"
                      className=""
                      name="card_expire_month"
                      value={formData.card_expire_month}
                      onChange={(e) => inputOnChange(e)}
                    >
                      <option>{''}</option>
                      {months.map((item, key) => {
                        return (
                          <option key={`m-${key}`} value={item.code}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    {formErrors.card_expire_month && (
                      <span className="text-red-600 text-sm">
                        {formErrors.card_expire_month}
                      </span>
                    )}
                  </div>
                  <div className="mb-2">
                    <label className="" htmlFor="card_expire_year">
                      Year
                    </label>
                    <select
                      id="card_expire_year"
                      className=""
                      name="card_expire_year"
                      value={formData.card_expire_year}
                      onChange={(e) => inputOnChange(e)}
                    >
                      <option>{''}</option>
                      {years.map((item, key) => {
                        return (
                          <option key={`y-${key}`} value={item.code}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    {formErrors.card_expire_year && (
                      <span className="text-red-600 text-sm">
                        {formErrors.card_expire_year}
                      </span>
                    )}
                  </div>
                  <div className="mb-2">
                    <label className="" htmlFor="card_code">
                      CVV
                    </label>
                    <input
                      id="card_code"
                      className=""
                      name="card_code"
                      type="text"
                      value={formData.card_code}
                      onChange={(e) => inputOnChange(e)}
                    />
                    {formErrors.card_code && (
                      <span className="text-red-600 text-sm">
                        {formErrors.card_code}
                      </span>
                    )}
                  </div>
                </div>
                <div className="mb-6">
                  <p>Charges will appear on your statement as TEMPOFLEXAPI</p>
                  <p>Call support (1-(877) 296-2574) or email to cancel.</p>
                  <p>
                    Once order is submitted you will receive a confirmation with
                    links to download the API from the app store.
                  </p>
                </div>
                <button
                  className="bg-teal-500 py-4 px-8 font-bold text-white tracking-wider rounded uppercase w-full"
                  type="submit"
                  value="Submit"
                >
                  Complete Checkout
                </button>
              </form>
            </div>
            {/*============ RIGHT COLUMN ============*/}
            <div className="px-8 col-span-2 lg:col-span-1 lg:border-l bg-slate-50">
              <div className="py-6">
                <h3 className="text-2xl font-bold mb-1 flex">
                  <span className="text-indigo-500">{product.name}</span>
                  <span className="ml-auto">
                    ${parseFloat(product.price).toFixed(2)}
                  </span>
                </h3>
                <div className="mt-4 mb-2 pb-2 border-b flex">
                  <span className="">Sub-Total</span>
                  <span className="ml-auto">
                    ${parseFloat(product.price).toFixed(2)}
                  </span>
                </div>
                <div className="mt-4 mb-2 pb-2 flex">
                  <span className="">Total</span>
                  <span className="ml-auto">
                    ${parseFloat(product.price).toFixed(2)}
                  </span>
                </div>
                <div className="">
                  <div className="flex mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6 text-green-500 mr-1"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                        clipRule="evenodd"
                      />
                    </svg>
                    500 requests / month
                  </div>
                  <div className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6 text-green-500 mr-1"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                        clipRule="evenodd"
                      />
                    </svg>
                    1 request / second
                  </div>
                </div>
                {/* ================================= */}
                <div className="mt-12 border-t pt-6">
                  <div className="text-center mb-12">
                    <h3 className="text-2xl">Why Developers Prefer Our API</h3>
                  </div>
                  <div className="mb-6">
                    <h4 className="text-lg">Fast Implementation</h4>
                    <p>
                      Clear documentation & demo code ensures you can implement
                      the API on your website or app easily.
                    </p>
                  </div>
                  <div className="mb-6 border-t border-b pt-6 pb-2">
                    <h4 className="text-lg">Fast Speed</h4>
                    <p>
                      Lightweight yet robust, the API is able to handle a
                      massive load of quests, process them and display results
                      super fast.
                    </p>
                  </div>
                  <div className="mb-6">
                    <h4 className="text-lg">Fast Scalability</h4>
                    <p>
                      The API offers seamless scalability, so you can just amp
                      up from our flexible pricing with a single click.
                    </p>
                  </div>
                </div>
                {/* ================================= */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CheckoutPage;
