import { Routes, Route } from 'react-router-dom';

import DefaultLayout from '@/layout/DefaultLayout';
import SimpleLayout from '@/layout/SimpleLayout';

import HomePage from '@/pages/index';
import TermsPage from '@/pages/terms';
import PrivacyPage from '@/pages/privacy';
import ContactPage from '@/pages/contact';
import CheckoutPage from '@/pages/checkout';

const renderRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<DefaultLayout />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Route>
      <Route path="/" element={<SimpleLayout />}>
        <Route path="/checkout/:id" element={<CheckoutPage />} />
      </Route>
    </Routes>
  );
};

export default renderRoutes;
