import React from 'react';
import { Outlet } from 'react-router-dom';

// components
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';

const DefaultLayout = () => {
  return (
    <div className="page-wrapper bg-gradient-to-l from-slate-50">
      <Outlet />
      <AppFooter />
    </div>
  );
};

export default DefaultLayout;
